<template>
  <div>
    <user-logs />
  </div>
</template>

<script>
import UserLogs from '@/components/InternalRecords/UserLogs.vue'

export default {
  components: { UserLogs },
  name: 'UserLog',
}
</script>
